.forums {
  padding: 40px 0;
}
.forums .navigation {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;
  gap: 15px;
}
.navigation .category {
  position: relative;
}
.forums .navigation h6 {
  text-transform: uppercase;
  font-weight: 400;
  position: relative;
  cursor: pointer;
}
.forums .navigation h6.active {
  color: var(--main-color);
}
.forums .navigation h6.active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background: var(--main-color);
  bottom: -10px;
  border-radius: 10px;
}

.forums .forums_page {
  display: flex;
  gap: 40px;
}
.forums .forums_page .categories {
  box-shadow: 0 12px 24px 0 rgba(99,100,102,.2);
  height: fit-content;
  max-width: 600px;
  height: 100%;
  background: var(--background-tow);
  padding-bottom: 16px;
}
.forums_page .categories head {
  background: var(--main-color);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
}
.forums_page .categories .item {
  padding: 10px 20px;
}
.forums_page .categories .item:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}
.categories .item .categories_name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.categories .item .categories_name h5 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 17px;
  margin: 0 !important;
}
.categories .item .categories_name span {
  color: gray;
}
.forums_page .categories .item p {
  color: gray;
  margin: 16px 0 !important;
}
.forums_page .categories .item .subcategories {
  display: flex;
  flex-wrap: wrap;
  gap: .15em .75em;
  width: 70%;
}
.forums_page .subcategories span.subcategory{
  display: flex;
  align-items: center;
  gap: 5px;
  color: gray;
  font-size: 14px;
}
.forums_page .subcategories span.subcategory span {
  width: 10px;
  height: 10px;
  background: var(--main-color);
}
.forums_page .latest {
  width: 100%;
}
.forums_page .latest head h5 {
  color: gray;
  padding-bottom: 15px;
  border-bottom: 1px solid gray;
  margin: 0 !important;
}
.forums_page .latest .item {
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
  border-bottom: 1px solid gray;
  gap: 20px;
}
.latest .item img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}
.latest .item .content {
  display: block;
}
.latest .item .content p {
  color: gray;
}
.latest .item .content p span {
  width: 10px;
  height: 10px;
  background: var(--main-color);
  margin-right: 10px;
  display: inline-block;
}
.latest .item span.number {
  font-size: 1.3rem;
  font-weight: 600;
  color: orangered;
}

@media (max-width: 991px) {
  .forums .forums_page {
    flex-direction: column;
  }
}
@media (max-width: 567px) {
  .forums .navigation {
    align-items: flex-start;
    flex-direction: column;
  }
}
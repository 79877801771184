.square .items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 10px;
    margin-top: 16px;
}
.square .items.cards {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}
.square .items .item {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 8px;
}
.square .items .item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #010206c7;
    width: 100%;
    height: 100%;
}
.square .items .item img {
    width: 100%;
}
.square .items .item h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    font-size: 1.7rem;
    text-transform: uppercase;
    font-weight: 500;
    transition: .3s;
    z-index: 2;
}
.square .items .item:hover h3 {
    color: var(--main-color);
}

@media (max-width: 567px) {
    .square .items {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
    .square .items.cards {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
    .square .items .item h3 {
        font-size: 1.2rem;
    }
}
.counter {
  padding: 50px 0;
}
.counter .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
}

.counter .counting {
  background: var(--background-tow);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  gap: 10px;
  border-radius: 7px;
  position: relative;
  overflow: hidden;
  max-width: 250px;
  width: 100%;
}

.counter .counting::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 8px;
  background: var(--main-color);
}

.counter .counting .counting_icon {
  font-size: 45px;
  color: var(--main-color);
}

.counter .counting .desc span {
  color: gray;
  font-size: 14px;
}
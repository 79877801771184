.product_detail {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.product_detail .top_product .container {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 30px;
}
.product_detail .top_product .image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
/* Product ribbon */
.product_detail .top_product .ribbon_product {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}
.product_detail .top_product .ribbon_product {
  --c: #8FBE00;
  padding: .6em 1em; /* you may need to adjust this based on your content */
  aspect-ratio: 1;
  display: grid;
  place-content: center;
  text-align: center;
  position: absolute;
  z-index: 0;
  right: 20px;
  top: 0;
  width: -moz-fit-content;
  width: 100px;
  box-sizing: border-box;

}
.product_detail .top_product .ribbon_product:before {
  content:"";
  position: absolute;
  z-index: -1;
  inset: 50% 0 auto;
  aspect-ratio: 1;
  background: color-mix(in srgb, var(--c), #000 35%);
  clip-path: polygon(calc(100%/3) 0,calc(200%/3) 0,100% 90%,calc(200%/3) 100%,calc(100%/3) 0,calc(200%/3) 0,calc(100%/3) 100%,0 90%);
}
.product_detail .top_product .ribbon_product:after {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  background: radial-gradient(35% 35%,#0000 96%,#0003 97% 99%,#0000) var(--c);
  clip-path: polygon(100.00% 50.00%,89.66% 55.22%,98.30% 62.94%,86.96% 65.31%,93.30% 75.00%,81.73% 74.35%,85.36% 85.36%,74.35% 81.73%,75.00% 93.30%,65.31% 86.96%,62.94% 98.30%,55.22% 89.66%,50.00% 100.00%,44.78% 89.66%,37.06% 98.30%,34.69% 86.96%,25.00% 93.30%,25.65% 81.73%,14.64% 85.36%,18.27% 74.35%,6.70% 75.00%,13.04% 65.31%,1.70% 62.94%,10.34% 55.22%,0.00% 50.00%,10.34% 44.78%,1.70% 37.06%,13.04% 34.69%,6.70% 25.00%,18.27% 25.65%,14.64% 14.64%,25.65% 18.27%,25.00% 6.70%,34.69% 13.04%,37.06% 1.70%,44.78% 10.34%,50.00% 0.00%,55.22% 10.34%,62.94% 1.70%,65.31% 13.04%,75.00% 6.70%,74.35% 18.27%,85.36% 14.64%,81.73% 25.65%,93.30% 25.00%,86.96% 34.69%,98.30% 37.06%,89.66% 44.78%); /* from https://css-generators.com/starburst-shape/ */
}

/* Image */
.top_product .image img {
  max-width: 450px;
  width: 100%;
}
.top_product .image .fotorama__nav {
  display: flex;
  gap: 20px;
}
.top_product .fotorama__nav .fotorama__thumb.active {
  border: 2px solid var(--main-color);
  border-radius: 4px;
  background: #8fae4d79;
}
.top_product .fotorama__nav img {
  width: 100px;
  cursor: pointer;
}
.top_product .product_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}
.top_product .product_content .items {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.top_product .product_content .items div {
  padding: 3px 6px;
  font-size: 16px;
  background: var(--clr);
  color: white;
  position: relative;
  border-radius: 2px;
}
.top_product .product_content .items div::before {
  content: "";
  position: absolute;
  width: 0;
  border: 15px solid;
  top: 0;
  right: -29px;
  border-color: transparent transparent transparent var(--clr);
}
.top_product .product_list .price {
  display: flex;
  align-items: flex-end;
  gap: 15px;
  margin-bottom: 20px;
}
.top_product .price p.price_now {
  color: var(--main-color);
  font-weight: bold;
  font-size: 40px;
  height: 50px;
  text-decoration: none;
}
.top_product .price p {
  font-weight: bold;
  color: #606060;
  text-decoration: line-through;
  font-size: 20px;
}
.top_product .stars {
  color: var(--main-color);
}
.top_product .additional_attributes_wrapper div {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}
.top_product .product_content .card_products-buton {
  color: #fff;
}
.top_product .product_content .product_addto_links {
  display: flex;
  align-items: center;
  gap: 10px;
}
.top_product .product_addto_links div {
  display: flex;
  align-items: center;
  color: var(--main-color);
}
.top_product .product_addto_links .share {
  gap: 5px;
}

.middle_product {
  background: var(--background-tow);
  padding: 60px 0;
}
.middle_product .items {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.middle_product .items .item {
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  transition: 0.3s;
}
.middle_product .items .item.active {
  color: #fff;
  background: var(--main-color);
}

.middle_product .product_details {
  margin: 30px 0;
}
.middle_product h2.title {
  font-weight: 700;
  font-size: 25px;
  color: var(--main-color);
  border-bottom: 4px solid var(--main-color);
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 10px;
}

.middle_product .product_details .product_content {
  display: flex;
  gap: 20px;
}

.middle_product .product_content .product_card {
  width: 50%;
}
.middle_product .product_card p {
  line-height: 30px;
  color: gray;
}

.middle_product .overall_stars {
  width: 50%;
}
.middle_product .overall_stars h3 {
  display: flex;
  align-items: center;
  margin: 15px 0;
  justify-content: space-between;
}
.middle_product .overall_stars .the-progress {
  height: 30px;
  background-color: var(--background);
  position: relative;
}
.middle_product .the-progress span {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: var(--main-color);
}
.middle_product .comments {
  padding: 60px 0;
}

.middle_product .comments .comment {
  display: flex;
  align-items: center;
  gap: 25px;
  margin: 30px 0;
}
.middle_product .comment img {
  width: 60px;
}
.middle_product .comment .reviews_comment {
  background-color: var(--background);
  padding: 25px;
  border-radius: 15px;
  position: relative;
  width: 50%;
}
.middle_product .comment .reviews_comment::before {
  content: "";
  width: 1.2rem;
  height: 1.2rem;
  display: block;
  background: var(--background);
  position: absolute;
  left: -0.6rem;
  transform: rotate(45deg);
  top: 5rem;
}
.middle_product .reviews_comment h5.title {
  font-weight: 700;
}
.middle_product .comment p {
  margin-top: 15px !important;
}

@media (max-width: 991px) {
  .product_detail .top_product .container {
    flex-direction: column;
  }
  .middle_product .items {
    gap: 40px;
  }
  .middle_product .product_details .product_content {
    flex-direction: column;
  }
  .middle_product .product_content .product_card {
    width: 100%;
  }
  .middle_product .comment .reviews_comment {
    width: 100%;
  }
  .product_detail .brands {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .middle_product .items {
    gap: 0;
  }
  .middle_product .overall_stars {
    width: 100%;
  }
  .product_detail .top_product .ribbon_product {
    right: -60px;
    top: -60px;
  }
}
@media (max-width: 567px) {
  .middle_product .items {
    flex-wrap: wrap;
    gap: 40px;
  }
  .product_detail .top_product .ribbon_product {
    right: 0;
    top: -75px;
  }
  .top_product .product_content .product_addto_links {
    flex-direction: column;
    align-items: flex-start;
  }
  .middle_product .comments .comment {
    flex-direction: column;
    align-items: flex-start;
  }
  .middle_product .comment .reviews_comment::before {
    top: -0.5rem;
    left: 1.4rem;
  }
}
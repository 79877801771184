.isEmptyFalse {
    display: flex;
    align-items: center;
    justify-content: center;
}
.isEmptyFalse .page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 15px;
}
.isEmptyFalse .page img {
    width: 200px;
}
.isEmptyFalse .page .isEmptyFalse_button {
    color: #fff;
    font-weight: bold;
}
.isEmptyTrue {
    width: 100%;
}
.isEmptyTrue .title_cart {
    padding: 20px 0px;
    margin-bottom: 20px;
    border-bottom: 1px solid gray;
}
.isEmptyTrue .cart_list {
    display: flex;
    flex-direction: column;
    gap: 17px;
}
.isEmptyTrue .cart_list .carts, .isEmptyTrue .cart_list .total_carts {
    background: var(--background-tow);
  border: 1px solid #60606078;
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  transition: .3s;
}
.isEmptyTrue .cart_list .total_carts {
    padding: 12px 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    flex-direction: row;
}
.isEmptyTrue .cart_list .total_carts .clear {
    background: red;
    color: white;
    border: none;
    margin-right: 10px;
}
.isEmptyTrue .cart_list .total_carts .clear:hover {
    background: #bd0a0a;
}
.isEmptyTrue .cart_list .total_carts .buy {
    background: #03a9f4;
    color: white;
    border: none;
}
.isEmptyTrue .cart_list .total_carts .buy:hover {
    background: #006cc1;
}
.isEmptyTrue .carts .cart {
    padding: 22px 35px;
    border-bottom: 1px solid #60606078;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
}

.isEmptyTrue .carts .cart img {
    width: 100px;
}
.isEmptyTrue .carts .cart h4 {
    font-size: 16px;
    width: 240px;
    text-align: center;
}
.isEmptyTrue .carts .cart div:nth-child(3) {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    width: 34%;
}
.isEmptyTrue .carts .cart .price {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    font-size: 16px;
}

.isEmptyTrue .carts .cart .price p {
    font-weight: bold;
    color: #606060;
    text-decoration: line-through;
}

.isEmptyTrue .carts .cart .price p.price_now {
    text-decoration: none;
    color: var(--main-color);
}
.isEmptyTrue .carts .cart .ribbon {
    font-size: 15px;
}
.isEmptyTrue .carts .cart .quantity {
    display: flex;
    background: var(--main-color);
    border-radius: 4px;
    color: white;
    border: 1px solid var(--color-palestine);
    width: fit-content;
}
.isEmptyTrue .carts .cart .quantity span {
    padding: 7px 12px;
    background: var(--background-tow);
    color: var(--color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid var(--color-palestine);
    border-left:  1px solid var(--color-palestine);
}

@media(max-width: 991px) {
    .isEmptyTrue .carts .cart div:nth-child(3) {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: 10px;
    }
}
@media(max-width: 767px) {
    .isEmptyTrue .carts .cart {
        flex-direction: column;
        gap: 10px;
    }
    .isEmptyTrue .carts .cart img {
        width: 100%;
    }
    .isEmptyTrue .carts .cart h4 {
        width: 100%;
        margin: 0;
    }
    .isEmptyTrue .carts .cart .delete, .isEmptyTrue .carts .cart .delete button {
        width: 100%;
    }
}

@media(max-width: 568px) {
    .isEmptyTrue .cart_list .total_carts {
        padding: 12px;
    }
    .isEmptyTrue .title_cart {
        text-align: center;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');

.header_bottom {
    transition: 0.4s;
}
.header_bottom.scroll {
    width: 100%;
    position: fixed;
    box-shadow: -3px 5px 20px #00000069;
    z-index: 4;
    top: 0;
}
.header_top {
    background: var(--main-color);
    color: #fff;
    padding: 10px 0;
    display: flex;
    align-items: center;
}
.header_middle {
    padding: 10px 0;
    display: flex;
    align-items: center;
    background: var(--background-tow);
}
.link {
    display: flex;
    gap: 6px;
    color: #fff !important;
    transition: .3s;
    align-items: center;
}
.link:hover {
    color: var(--main-color-hover) !important;
}
.logo {
    width: 150px;
    height: 150px;
}
.search div {
    max-width: 400px;
    width: 100%;
    height: 40px;
    padding: 7px 0px;
    background: var(--background);
    display: flex;
    border-radius: 7px;
    align-items: center;
    overflow: hidden;
}
.search input {
    width: 100%;
    border: none;
    outline: none;
    color: var(--color);
    background: transparent;
    padding-left: 10px;
}
.search .icon_search {
    padding: 6px;
    width: 40px;
    height: 40px;
    font-size: 18px;
    background: var(--main-color);
    border-radius: 0;
    color: #fff;
}

.palestine {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f6f7f866;
    padding: 10px;
    color: var(--color-palestine);
    border-radius: 10px;
    border: 2px solid #e8eaee;
    transition: .3s;
    cursor: pointer;
}
.palestine:hover {
    background: #ebf5ff;
    border-color: #99ccff;
    color: #717171;
}
.flag_palestine img {
    width: 45px;
    border-radius: 6px;
}
.header_bottom {
    background: var(--main-color);
    color: #fff;
    padding: 10px 0;
    display: flex;
    align-items: center;
    position: relative;
}
.MuiSelect-select {
    padding: 4px 10px !important;
    border:  1px solid #5d8745 !important;
    outline: none;
    background: #6a994ea1 !important;
    color: white !important;
    border-radius: 4px !important;
    font-size: 17px !important;
}
.navigation .menu_mobil {
    display: none !important;
}
.icon_header {
    cursor: pointer;
    padding: 4px 7px;
    background: #6a994ea1;
    border-radius: 4px;
    font-size: 17px;
    border:  1px solid #5d8745;
    transition: .3s;
    position: relative;
}
.icon_header:hover {
    background: #6a994e;
}
.icon_market_number {
    background: #ee9b00;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 800;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    top: -3px;
    right: -11px;
    color: #292929;
}

.navigation_wrapper {
    position: relative;
}
.links-text {
    transition: .4s;
    cursor: pointer;
    width: fit-content;
}
.links-text:hover {
    color: #33691e;
}

.market_icon {
    position: relative;
}
.market_icon .market_menu_header {
    right: -20px;
    min-width: 380px;
    max-width: 550px;
    color: var(--color) !important;
}
.market_icon .market_menu_header span {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 3px;
    background: #558b2f;
}
.market_icon:hover .market_menu_header span {
    animation: anim 5s linear forwards;
}
@keyframes anim {
    100%{
        width: 100%;
    }
}
.market_icon:hover .market_menu_header {
    top: 53px;
    z-index: 100;
    opacity: 1;
    visibility: visible;
}
.market_icon .market_menu_header ul {
    margin: 0 !important;
    padding: 0 !important;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.market_icon .cart_Tab {
    position: absolute;
    top: 90px;
    right: -25px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 400px;
    z-index: 100;
    background-color: var(--background-tow);
    border: 1px solid var(--border-color);
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    color: var(--color);
    box-shadow: 1px 1px 9px #00000094;
}
.market_icon .cart_Tab::before {
    content: "";
    position: absolute;
    top: -29px;
    right: 24px;
    border: 20px solid;
    border-color: transparent transparent var(--background-tow) transparent;
}
.market_icon .cart_Tab {
    text-align: center;
}
.market_icon:hover .cart_Tab {
    top: 48px;
    visibility: inherit;
    opacity: 1;
}
.cart_Tab .list_cart .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
}
.cart_Tab .list_cart .head a {
    color: var(--color);
    transition: .4s;
    font-weight: 500;
}
.cart_Tab .list_cart .head a:hover {
    color: var(--main-color);
}
.cart_Tab .list_cart .content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-x: auto;
    max-height: 400px;
}
.cart_Tab .list_cart .content::-webkit-scrollbar {
    width: 0.5rem;
  }
  
.cart_Tab .list_cart .content::-webkit-scrollbar-track {
    background: transparent;
}
  
.cart_Tab .list_cart .content::-webkit-scrollbar-thumb {
    background: var(--main-color);
    border-radius: 5rem;
}
.list_cart .content .item {
    display: flex;
    gap: 7px;
    position: relative;
    border-bottom: 1px solid gray;
    padding-bottom: 8px;
}
.list_cart .content .item img {
    width: 85px;
    height: fit-content;
}
.list_cart  .item .text-content p {
    font-size: 14px;
    font-weight: 400;
}
.list_cart  .item .price {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    font-size: 18px;
}
  
.list_cart  .item .price p {
    font-weight: bold;
    color: #606060;
    text-decoration: line-through;
}
.list_cart  .item .price p.price_now {
    text-decoration: none;
    color: var(--main-color);
}
.list_cart  .item .ribbon_cart {
    position: absolute;
    right: 0;
    color: gray;
    font-size: 13px;
    bottom: 2px;
}
.list_cart  .item .close {
    padding: 3px 5px;
    background: var(--main-color);
    color: white;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .4s;
}
.list_cart  .item .close:hover {
    background: red;
}
.cart_Tab .list_cart .total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}
.all_icon_header {
    font-size: 30px !important;
}

@media (max-width: 1400px) {
    .header_bottom .col-lg-5 {
        width: fit-content !important;
    }
    .header_bottom .col-lg-7 {
        width: fit-content !important;
    }
}

@media (max-width: 1200px) {
    .header_bottom .col-lg-5 {
        width: fit-content !important;
    }
    .header_bottom .col-lg-7 {
        width: fit-content !important;
    }
}

@media (max-width: 991px) {
    .header_middle .row {
        justify-content: space-between;
    }
    .header_middle .col-lg-3 {
        width: 47%;
    }
    .header_middle .col-lg-6 {
        display: none !important;
    }
    .navigation .menu {
        display: none !important;
    }
    .navigation .menu_mobil {
        display: flex !important;
    }
    .menu_mobil .header_mobil {
        position: fixed;
        width: 300px;
        height: 100%;
        top: 0;
        left: 0;
        background: var(--main-color);
        z-index: 1000;
        display: none;
        flex-direction: column;
        justify-content: space-between;
        padding: 30px 10px;
        box-shadow: 2px 2px 20px 0px #00000059;
    }
    .menu_mobil .header_mobil.open {
        display: flex;
    }
    .menu_mobil .header_mobil .close {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
    }
    .menu_mobil .header_mobil .icon_soldier {
        width: 100%;
        margin: 30px 0px;
    }
    .menu_mobil .header_mobil .menu_name h2 {
        font-family: "Roboto Slab", serif;
    }
    .menu_mobil .header_mobil ul li {
        padding: 7px 30px;
        text-align: center;
        font-size: 17px;
        text-transform: uppercase;
        width: 100%;
        display: flex;
        align-items: center;
    }
    .header_bottom .col-sm-2 {
        width: fit-content;
    }
    .all_icon_header {
        font-size: 27px !important;
    }
    .header_middle .row {
        justify-content: space-between !important;
    }
    .header_bottom .row {
        justify-content: space-between !important;
    }
}

@media (max-width: 767px) {
    .header_middle .col-lg-3 {
        width: 60%;
    }
    .header_bottom .row {
        justify-content: space-between;
    }
    .header_bottom .col-lg-4 {
        display: none;
    }
    .header_bottom .col-lg-2 {
        width: fit-content;
        padding: 0;    
    }
}

@media (max-width: 576px) {
    .header_middle .col-lg-3:last-child {
        display: none !important;
    }
    .header_middle .row {
        justify-content: center;
    }
    .header_middle .col-lg-3 {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .header_top .header_top_left {
        position: relative;
    }
    .header_top span.phone_Number {
        position: absolute;
        background: var(--main-color-hover);
        padding: 4px 7px;
        top: 45px;
        left: 55px;
        opacity: 0;
        z-index: -10;
        transition: 0.5s;
        display: flex;
        align-items: center;
    }
    .header_top span.phone_Number::before {
        content: "";
        position: absolute;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent var(--main-color-hover) transparent;
        left: 3px;
        top: -18px;
    }
    .header_top_left span.need {
        cursor: pointer;
    }
    .header_top_left span.need:hover + span.phone_Number {
        top: 36px;
        opacity: 1;
        z-index: 10;
    }
    .header_top .row>* {
        width: fit-content;
    }
    .header_top .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .MuiSelect-select {
        font-size: 13px !important;
    }
    p.links-text span.all_departments {
        display: none;
    }
    .header_bottom .gap-3 {
        gap: 0.5rem !important;
    }
    .header_bottom .row {
        justify-content: center;
    }
    .header_nav {
        width: 300px;
    }
    .market_icon .cart_Tab {
        right: -10px;
        width: 347px;
    }
    .market_icon .cart_Tab::before {
        right: 9px;
    }
}
header .Div_menu {
    text-align: center;
}

.shopping_cart .cart_item {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.shopping_cart .cart_item .cart_item-image {
    width: 100px;
}
.shopping_cart .cart_item .close {
    color: white;
    font-weight: bold;
}
